import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import Banners from 'config/constants/banner/banners';
import { PuidsType, defaultCallback } from 'config/constants/common';

import s from './styles.module.css';

type InjectorType = (index: number, puids: PuidsType) => React.ReactNode;

type SecondLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
  bottomAdName: Banners;
  widgets?: React.ReactNode;
  injectElements?: InjectorType;
};

/**
 * Компонент для отрисовки новостей начиная со второго экрана для вертикали Новости.
 * @param clusterIds - список идентификаторов кластеров;
 * @param puids - объект настроек для рекламы;
 * @param bottomAdName - название баннера в конце блока;
 * @param widgets - компонент с компонентами виджетов для боковой колонки;
 * @param injectElements - функция для внедрения компонентов внутрь списка новостей.
 */
export const SecondLevel = memo(function RedesignNewsSecondLevel({
  clusterIds,
  puids,
  bottomAdName,
  widgets,
  injectElements = defaultCallback as InjectorType,
}: SecondLevelPropsType) {
  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.newsBlock}>
          {clusterIds.map((clusterId, index) => {
            const element = injectElements?.(index, puids);
            const news = (
              <div className={cn(s.block, s.card)} key={clusterId}>
                News card {clusterId}
              </div>
            );

            if (element) {
              return [news, element];
            }

            return news;
          })}
        </div>
      </div>
      <div className={s.widgets}>{widgets}</div>
      <div className={cn(s.sideAd)}>
        <Ad
          puids={puids}
          name={Banners['240x400']}
          withoutMarginBottom
          isLazy={false}
          isSticky
        />
      </div>
      <div className={s.bottomAd}>
        <Ad name={bottomAdName} puids={puids} withoutMarginBottom />
      </div>
    </div>
  );
});
