import React, { memo, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { CardContextWrapper } from 'common/components/CardContextWrapper';
import {
  RCM_BLOCK_TYPE,
  useBlockInitAtd,
  usePageViewAtd,
} from 'common/hooks/useRcm';
import { HomeHeader } from 'common/pages/headers/HomeHeader';
import { fetchFullRecsData } from 'common/redux/newPages/home/asyncs';
import {
  selectHomeExcludedClustersIds,
  selectHomePageClusterIds,
  selectHomePageHasNextPage,
  selectHomePagePuids,
  selectHomePageRecommendClusterIds,
} from 'common/redux/newPages/home/selectors';
import { selectForceRedesignValue } from 'common/redux/runtime/selectors';
import { NATIVE_PUIDS, modifyPuids } from 'config/constants/common';
import { Base } from 'desktop/components/Base';
import { Trigger } from 'desktop/components/Trigger';
import { useSliceClusters } from 'desktop/hooks/useSliceClusters';

import s from '../../css/page.css';

import { HomeRecommends } from './HomeRecommends';
import { RedesignBase } from './RedesignBase';

/** Компонент домашней страницы */
const Home = memo(() => {
  const homeClusterIds = useSelector(selectHomePageClusterIds, shallowEqual);
  const homeRecommendClustersIds = useSelector(
    selectHomePageRecommendClusterIds,
    shallowEqual,
  );
  const homeExcludedClustersIds = useSelector(
    selectHomeExcludedClustersIds,
    shallowEqual,
  );
  const homeRawPuids = useSelector(selectHomePagePuids, shallowEqual);
  const homeHasNextPage = useSelector(selectHomePageHasNextPage);
  const forceRedesign = useSelector(selectForceRedesignValue);

  const homePuids = useMemo(
    () => modifyPuids(homeRawPuids, NATIVE_PUIDS),
    [homeRawPuids],
  );

  usePageViewAtd();
  useBlockInitAtd(RCM_BLOCK_TYPE.homeDesktop);

  const { slicedClusters } = useSliceClusters([
    ...homeClusterIds,
    ...homeRecommendClustersIds,
  ]);

  return (
    <CardContextWrapper
      selectClustersIds={selectHomePageClusterIds}
      rcmBlockType={RCM_BLOCK_TYPE.homeDesktop}
    >
      <div className={s.root}>
        <HomeHeader />
        {forceRedesign ? (
          <RedesignBase puids={homePuids} />
        ) : (
          <Base
            clusters={slicedClusters}
            puids={homePuids}
            excludedIds={homeClusterIds}
          />
        )}
        <Trigger
          ComponentRecommends={HomeRecommends}
          fetchRecsData={fetchFullRecsData}
          excludedRecsIds={homeExcludedClustersIds}
          hasNextPage={homeHasNextPage}
        />
      </div>
    </CardContextWrapper>
  );
});

export default Home;
