import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Head } from 'common/components/headers/Head';
import { PreloadMainImage } from 'common/components/headers/PreloadMainImage';
import { ItemListSchema } from 'common/components/schemaOrg/ItemList';
import { WebSiteSchema } from 'common/components/schemaOrg/WebSite';
import { selectHomePageClusterInCardData } from 'common/redux/newPages/home/selectors';
import {
  selectProjectDetails,
  selectProjectId,
  selectIsMobile,
  selectProjectName,
} from 'common/redux/runtime/selectors';
import { NEW_DESCRIPTIONS, NEW_TITLES } from 'config/constants/changedMeta';
import { useHeader } from 'desktop/hooks/useHeader';
import { PROXY_DOMAINS } from 'utils/urlGenerator';

/**
 * Мета домашней страницы.
 */
export const HomeHeader = memo(() => {
  const isMobile = useSelector(selectIsMobile);
  const projectId = useSelector(selectProjectId);
  const projectDetails = useSelector(selectProjectDetails, shallowEqual);
  const projectName = useSelector(selectProjectName);

  const { clustersBySchema } = useHeader(selectHomePageClusterInCardData);

  const pageDescription =
    projectName?.toLowerCase() === PROXY_DOMAINS.Avtorambler ||
    !NEW_DESCRIPTIONS[projectId]
      ? projectDetails?.description
      : NEW_DESCRIPTIONS[projectId];

  return (
    <>
      <PreloadMainImage image={clustersBySchema[0]?.image} />
      <Head
        top100PageType="main"
        pageTitle={NEW_TITLES[projectId] || projectDetails?.title}
        pageDescription={pageDescription}
        pageKeywords={projectDetails?.keywords}
        pageUrl="/"
        rssUrl="/rss/head/?limit=100"
        isMobile={isMobile}
        data-test="head"
      />
      <ItemListSchema items={clustersBySchema} />
      <WebSiteSchema />
    </>
  );
});
