import React from 'react';

import { Ad } from 'common/components/Ad';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';

import s from './styles.module.css';

export const SLICE_CONFIG = [7, 5, 5, 8, 9];

export const LAST_CHUNK_SIZE = 9;

const NATIVE_BANNER_LIST_CLASS_NAME =
  'banner--native-context--redesign_list_relaunch';
const NATIVE_BANNER_PRELOAD_HEIGHT = 115;

export const INJECTORS = [
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          onRenderClassName={s.adPlaceholderLoaded}
          preloadHeight={100}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          onRenderClassName={s.adPlaceholderLoaded}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 1) {
      return (
        <Ad
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
          onRenderClassName={s.adPlaceholderLoaded}
        />
      );
    }

    if (index === 2) {
      return (
        <Ad
          name={Banners.NativeInRecs}
          puids={puids}
          withoutMarginBottom
          className={'banner--native-recs_list_relaunch'}
          preloadHeight={164}
          onRenderClassName={s.adPlaceholderLoaded}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 1) {
      return (
        <Ad
          name={Banners.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
          onRenderClassName={s.adPlaceholderLoaded}
        />
      );
    }
  },
];
