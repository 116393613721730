import cn from 'classnames';
import chunk from 'lodash/chunk';
import React, { useMemo } from 'react';

import { Ad } from 'common/components/Ad';
import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';

import { FirstLevel } from './components/FirstLevel';
import { SecondLevel } from './components/SecondLevel';
import { INJECTORS, LAST_CHUNK_SIZE, SLICE_CONFIG } from './constants';

import s from './styles.module.css';

import 'desktop/css/banner--native-context_list_relaunch.css';
import 'desktop/css/banner--native-context_widget_relaunch.css';

const TEST = [
  '45555555',
  '45555556',
  '45555557',
  '45555558',
  '45555559',
  '45555560',
  '45555561',
  '45555562',
  '45555563',
  '45555564',
  '45555565',
  '45555566',
  '45555567',
  '45555568',
  '45555569',
  '45555570',
  '45555571',
  '45555572',
  '45555573',
  '45555574',
  '45555575',
  '45555576',
  '45555577',
  '45555578',
  '45555579',
  '45555580',
  '45555581',
  '45555582',
  '45555583',
  '45555584',
  '45555585',
  '45555586',
  '45555587',
  '45555588',
  '45555589',
  '45555590',
  '45555591',
];

type RedesignBaseProps = {
  puids: PuidsType;
};

/**
 * Базовый костяк для всей домашней страницы Новостей.
 * @param puids - объект с настройками для рекламы.
 */
export const RedesignBase = withErrorBoundary(function RedesignBase({
  puids,
}: RedesignBaseProps) {
  const clusterIds = TEST; // useSelector(...);

  const slicedTest = useMemo(() => {
    const copy = [...clusterIds];

    const sliced = SLICE_CONFIG.map((value) =>
      copy.length > 0 ? copy.splice(0, value) : undefined,
    ).filter((val) => !!val) as string[][];

    if (copy.length <= 0) {
      return sliced;
    }

    return [...sliced, ...chunk(copy, LAST_CHUNK_SIZE)];
  }, [clusterIds]);

  return (
    <div className={s.root}>
      <FirstLevel clusterIds={slicedTest[0]} puids={puids} />
      <div className={s.bottomBlock}>
        <div className={cn(s.block, s.calendar)}>Calendar</div>

        <Ad name={Banners['100x70']} puids={puids} withoutMarginBottom />
      </div>
      {slicedTest.slice(1).map((clusterIds, index) => (
        <SecondLevel
          clusterIds={clusterIds}
          key={index}
          injectElements={
            index >= INJECTORS.length
              ? INJECTORS[INJECTORS.length - 1]
              : INJECTORS[index]
          }
          puids={puids}
          bottomAdName={Banners.Superfooter}
          widgets={
            index === 0 ? (
              <>
                <div className={cn(s.block, s.converter)}>Converter</div>
                <div className={cn(s.block, s.converter)}>Converter</div>
              </>
            ) : (
              <>
                <div className={cn(s.block, s.recs)}>Recommends</div>
              </>
            )
          }
        />
      ))}
    </div>
  );
});
