import { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectAllClusterIds } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { fetchFullRecsData } from 'common/redux/newPages/home/asyncs';
import { selectHomePageClusterIds } from 'common/redux/newPages/home/selectors';
import { useFetchRecsData } from 'desktop/hooks/useFetchRecsData';

/**
 * Компонент с логикой подрузки рекомендов для домашней страницы.
 */
export const HomeRecommends = memo(() => {
  const topClusterIds = useSelector(selectHomePageClusterIds, shallowEqual);
  // Рекомендательные кластера из колонки
  const topProjectWidgetsClusterIds = useSelector(
    selectWidgetsTopProjectAllClusterIds,
    shallowEqual,
  );
  const topMainWidgetsClustersIds = useSelector(
    selectWidgetsTopMainClusterIDs,
    shallowEqual,
  );

  const excludedClustersArray = useMemo(
    () => [
      topClusterIds,
      topProjectWidgetsClusterIds,
      topMainWidgetsClustersIds,
    ],
    [topClusterIds, topProjectWidgetsClusterIds, topMainWidgetsClustersIds],
  );

  useFetchRecsData({
    fetchRecsData: fetchFullRecsData,
    excludedClustersArray,
  });

  return null;
});
