import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { SberWidgetWrapper } from 'common/components/SberWidget';
import {
  selectIsRussia,
  selectPromoBannerEnabled,
  selectPromoBannerPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/constants/banner/contants';
import { PuidsType } from 'config/constants/common';

import s from './styles.module.css';

type FirstLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
};

/**
 * Компонент первого этажа новостной вертикали.
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - файл с настройками рекламы.
 */
export const FirstLevel = memo(function FirstLevel({
  clusterIds,
  puids,
}: FirstLevelPropsType) {
  const isRussiaRegion = useSelector(selectIsRussia);
  const isPromoWidgetEnabled = useSelector(selectPromoBannerEnabled);
  const isPromoWidgetPlaceholderEnabled = useSelector(
    selectPromoBannerPlaceholderEnabled,
  );

  return (
    <div className={s.root}>
      <div className={s.main}>
        <div className={cn(s.block, s.giga)}>Gigachat</div>
        <div className={s.news}>
          {clusterIds.map((clusterId, index) => (
            <div
              className={cn(s.block, s.card, index === 0 && s.big)}
              key={clusterId}
            >
              News card {clusterId}
            </div>
          ))}
        </div>
      </div>
      <div className={s.sideAd}>
        <div className={s.sticky}>
          {isRussiaRegion && isPromoWidgetEnabled && (
            <Ad
              name={Banners.PromoWidget}
              puids={puids}
              isLazy={false}
              /* Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился */
              className={cn(
                s.promo,
                isPromoWidgetPlaceholderEnabled && s.visible,
              )}
              preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
              disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
              onRenderClassName={s.visible}
            />
          )}
          <SberWidgetWrapper />
          <Ad
            puids={puids}
            name={Banners['240x400']}
            withoutMarginBottom
            isLazy={false}
          />

          <Ad
            name={Banners.Context}
            puids={puids}
            className={cn(
              s.adPlaceholder,
              'banner--native-context_widget_relaunch',
            )}
            onRenderClassName={s.adPlaceholderLoaded}
            isLazy={false}
            preloadHeight={100}
          />
        </div>
      </div>
    </div>
  );
});
