export const NEW_TITLES: { [key: number]: string } = {
  2: 'Автоновости, тест-драйвы, обзоры на новинки автопрома',
  5: 'Новости спорта, турнирные таблицы и расписания матчей',
  8: 'Новости здоровья, медицины и здравоохранения',
  10: 'Новости кино и телевидения. Звезды фильмов и сериалов',
};

export const NEW_DESCRIPTIONS: { [key: number]: string } = {
  2: 'Последние новости автопрома, обзоры новинок, тест-драйвы и краш-тесты, резонансные аварии и скандальные нарушения. Ситуация с авторынком в России и мире на портале Рамблер/авто',
  11: 'Самые свежие новости из мира туризма, путешествий и отдыха в России и за границей на портале Рамблер/путешествия',
};
